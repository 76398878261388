

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import FEATURES from '@/modules/common/constants/features.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import InsightsService, { InsightsServiceS } from '../insights.service';
import InsightsHintTooltip from './insights-hint-tooltip.vue';

@Component({
    components: {
        CustomSelect,
        InsightsHintTooltip,
    },
})
export default class InsightsHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(InsightsServiceS) private insightsService!: InsightsService;

    get isChainOrCluser() {
        return !this.$route.path.startsWith('/hotel');
    }

    get urlChainClusterParam() {
        if (this.userService.isClusterUser) {
            return 'cluster';
        }

        return 'chain';
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;
        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get feature() {
        return FEATURES.INSIGHTS;
    }

    // get backRoute() {
    //     const branch = this.$route.name!.split('.')[0];

    //     switch (branch) {
    //         case 'chain':
    //             return {
    //                 name: 'chain.markets', // NOTE: Add .cluster after adding chain level
    //                 params: this.$route.params,
    //             };

    //         case 'cluster':
    //             return {
    //                 name: 'cluster.markets',
    //             };

    //         default: return {};
    //     }
    // }

    get dateRangeItems(): Item[] {
        return [{
            name: 'Today',
            value: 1,
        }, {
            name: 'Next 7 days',
            value: 7,
        }, {
            name: 'Next 14 days',
            value: 14,
        }, {
            name: 'Next 30 days',
            value: 30,
        }, {
            name: 'Next 60 days',
            value: 60,
        }, {
            name: 'Next 90 days',
            value: 90,
        }];
    }

    get dateRange() {
        return this.insightsService.settings.dateRange;
    }

    set dateRange(value: number) {
        this.insightsService.setSettingValue('dateRange', value);
    }
}
