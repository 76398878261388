
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import InsightsService, { InsightsServiceS } from '../insights.service';
import InsightTypeModel from '../models/insight-type.model';

@Component({
    components: {
        CustomMultiSelect,
    },
})
export default class InsightsFilters extends Vue {
    @Inject(InsightsServiceS) insightsService!: InsightsService;
    @Inject(CompsetsServiceS) compsetsService!: CompsetsService;

    get insightsTypesItems() {
        return this.insightsService.insightTypeItems;
    }

    get insightsTypes() {
        return this.insightsService.settings.insightTypes;
    }

    set insightsTypes(value: InsightTypeModel[]) {
        this.insightsService.setSettingValue('insightTypes', value);
    }

    get providerItems() {
        return this.insightsService.providerItems;
    }

    get providers() {
        return this.insightsService.settings.providers;
    }

    set providers(value: Item[]) {
        this.insightsService.setSettingValue('providers', value);
    }

    get isLoading() {
        return this.insightsService.isTypesLoading || this.compsetsService.isLoading;
    }
}
