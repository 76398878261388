
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { RouterLink } from 'vue-router';
import moment from 'moment';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import InsightsService, { InsightsServiceS } from '../insights.service';
import { InsightType } from '../constants';

@Component({
    components: {
        RouterLink,
        Dialog,
    },
})
export default class InsightCard extends Vue {
    @Inject(InsightsServiceS) private insightsService!: InsightsService;

    @Prop()
    id!: string;

    @Prop()
    insightType!: {
        subType: number;
        value: InsightType;
        name: string;
        color: string;
        bgcolor: string;
    };

    @Prop()
    date!: Date;

    @Prop()
    contentLink!: string;

    @Prop({
        default: false,
    })
    isNew!: boolean;

    @Prop({
        default: false,
    })
    isDeleted!: boolean;

    isPending = false;
    isDeleteConfirmation = false;

    get formatedDate() {
        return this.date ? `From ${moment(this.date).format('MMMM DD, YYYY')}` : '--/--/--';
    }

    async handleToggleViewed() {
        this.isPending = true;
        if (this.isNew) {
            await this.insightsService.setGroupViewed(this.id);
        } else {
            await this.insightsService.setGroupUnviewed(this.id);
        }
        this.isPending = false;
    }

    async handleDelete() {
        this.isPending = true;
        await this.insightsService.deleteGroup(this.id);
        this.isPending = false;
        this.isDeleteConfirmation = false;
    }

    text(type: InsightType, subType: number) {
        switch (type) {
            case InsightType.RATE:
                if (subType === 0) {
                    return 'New rate type was detected in at least one of the hotels in compset. Check how it impacts the rates.';
                }
                return 'The main hotel is <span class="bad">missing</span> a rate type which is offered by at least one of the hotels in compset. Check how it impacts the rates.';
            case InsightType.LOS:
                if (subType === 0) {
                    return 'The main hotel LOS rate is <span class="good">lower</span> than the competitors average. Click here to review all cases';
                }
                return 'The main hotel LOS rate is <span class="bad">higher</span> than the competitors average. Click here to review all cases';
            case InsightType.PROMOTION:
                if (subType === 0) {
                    return '<span class="norm">New</span> Promotion was detected in at least one of the hotels in compset. Check how it impacts the rates or position.';
                }
                return '<span class="norm">New</span> discount percentage was detected in at least one of the hotels in compset. Check how it impacts the rates or position.';
            case InsightType.VISIBILITY:
                if (subType === 0) {
                    return 'The main hotel Position is drastically <span class="bad">lower</span> than the competitors average.';
                }
                return 'The main hotel Position is drastically <span class="good">higher</span> than the competitors average.';
            case InsightType.ROOMS:
                return 'At least one <span class="norm">new</span> room was detected in scan for one or more of the hotels in compset. Check if a new mapping is needed.';
            case InsightType.DEVICE:
                return 'A significant <span class="norm">difference</span> in rate was detected when comparing between web rates and mobile/app rates.  Click here to review all cases.';
            case InsightType.RATE_TRENDS:
                if (subType === 1) {
                    return 'A significant <span class="bad">increase</span> change in rate was detected in at least one of the hotels in compset. Click here to review all cases.';
                }
                return 'A significant <span class="good">decrease</span> change in rate was detected in at least one of the hotels in compset. Click here to review   all cases.';
            case InsightType.MARKET_LEADER:
                return 'A market leader was detected for the primary compset. Click here for more information.';
            default:
                return '';
        }
    }
}
