
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import InsightCard from './insight-card.vue';
import InsightsService, { InsightsServiceS } from '../insights.service';
import { InsightType, InsightDayRoute } from '../constants';

@Component({
    components: {
        InsightCard,
        DotsSpinner,
    },
})
export default class InsightGrid extends Vue {
    @Inject(InsightsServiceS) insightsService!: InsightsService;

    updated() {
        // Load more insights in case no scroll on screen, but there are still some not loaded data (for hight screen res).
        const grid = this.$refs.grid as HTMLElement;
        const elH = grid.clientHeight;
        const elS = grid.scrollHeight;

        if (elS <= elH && this.insightsService.count > this.insightsService.skip && !this.insightsService.isInsightLoading) {
            this.loadMoreInsights();
        }
    }

    handleGridScroll(e: UIEvent) {
        // Load more insights if scroll comes to bottom and there are still some not loaded data.
        if (this.insightsService.count <= this.insightsService.skip) {
            return;
        }

        const el = e.target as HTMLElement;
        const loadingOffset = el.scrollHeight * 0.2;

        if (el.scrollTop + el.offsetHeight > el.scrollHeight - loadingOffset && !this.insightsService.isInsightLoading) {
            this.loadMoreInsights();
        }
    }

    getTypeName(type: string) {
        const item = this.insightsService.insightTypeItems.find(t => t.value === type);
        return item?.name || type;
    }

    typeColor(type: InsightType) {
        // Add more colors from design if needed
        const colorPairs = [
            ['#52A9FF', 'rgba(82, 169, 255, 0.06)'],
            ['#A7881C', 'rgba(255, 238, 82, 0.08)'],
            ['#EE2841', 'rgba(241, 73, 80, 0.06)'],
            ['#555', 'rgba(170, 170, 170, 0.06)'],
            ['#21A433', 'rgba(57, 181, 74, 0.06)'],
            ['#EE7010', 'rgba(200, 90, 10, 0.06)'],
            ['#1FBF9F', 'rgba(23, 161, 121, 0.08)'],
        ];

        const typeIndex = this.insightsService.insightTypeItems.findIndex(t => t.value as InsightType === type);
        const colorIndex = typeIndex % colorPairs.length;

        return colorPairs[colorIndex];
    }

    get insights() {
        return this.insightsService.groups.map(ins => {
            const [color, bgcolor] = this.typeColor(ins.type);
            const typeWithPopup = InsightDayRoute[ins.type.toUpperCase() as keyof typeof InsightDayRoute];

            return {
                id: ins.groupId,
                insightType: {
                    value: ins.type,
                    name: this.getTypeName(ins.type),
                    subType: ins.subType,
                    color,
                    bgcolor,
                },
                read: ins.read,
                deleted: !!ins.deleted,
                content: {
                    link: (typeWithPopup && !ins.deleted) ? `insights/group/${ins.groupId}` : null,
                },
                date: new Date(ins.date),
            };
        });
    }

    loadMoreInsights() {
        this.insightsService.loadMoreInsights();
    }
}
